// @ts-check

import { format } from 'fecha';

const simplePlural = (word, times) => (times === 1 ? word : `${word}s`);

export const timeSince = (date, translate) => {
	const seconds = Math.floor((new Date() - date) / 1000);
	let interval = seconds / 31536000;

	if (interval > 1) { return `${Math.floor(interval)} ${simplePlural(translate('Global.DateFormat.year'), Math.floor(interval))} ago`; }

	interval = seconds / 2592000;
	if (interval > 1) { return `${Math.floor(interval)} ${simplePlural(translate('Global.DateFormat.month'), Math.floor(interval))} ago`; }

	interval = seconds / 86400;
	if (interval > 1) { return `${Math.floor(interval)} ${simplePlural(translate('Global.DateFormat.day'), Math.floor(interval))} ago`; }

	interval = seconds / 3600;
	if (interval > 1) { return `${Math.floor(interval)} ${simplePlural(translate('Global.DateFormat.hour'), Math.floor(interval))} ago`; }

	interval = seconds / 60;
	if (interval > 1) { return `${Math.floor(interval)} ${simplePlural(translate('Global.DateFormat.minute'), Math.floor(interval))} ago`; }

	return translate('Global.DateFormat.justNow');
};

export const formatCountdown = (date, until) => {
	let seconds = Math.floor((until - date) / 1000);
	let result = '';

	let interval = seconds / 31536000;
	if (interval > 1) {
		result += `${Math.floor(interval)}y `;
		seconds -= Math.floor(interval) * 31536000;
	}

	interval = seconds / 2592000;
	if (interval > 1) {
		result += `${Math.floor(interval)}m `;
		seconds -= Math.floor(interval) * 2592000;
	}

	interval = seconds / 86400;
	if (interval > 1) {
		result += `${Math.floor(interval)}d `;
		seconds -= Math.floor(interval) * 86400;
	}

	interval = seconds / 3600;
	if (interval > 1) {
		result += `${Math.floor(interval)}h `;
		seconds -= Math.floor(interval) * 3600;
	}

	interval = seconds / 60;
	if (interval > 1) {
		result += `${Math.floor(interval)}m `;
		seconds -= Math.floor(interval) * 60;
	}

	result += `${Math.floor(seconds)}s`;

	return result;
};

/** @param {number} duration */
export const formatDuration = (duration) => {
	let seconds = Math.round(duration);

	const hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	const minutes = Math.floor(seconds / 60);
	seconds %= 60;

	let result = '';
	if (hours > 0) {
		result = `${hours}h ${minutes}m ${seconds}s`;
	} else if (minutes > 0) {
		result = `${minutes}m ${seconds}s`;
	} else {
		result = `${seconds}s`;
	}

	return result;
};

const isSameDate = (dateA, dateB) => (
	dateA.getFullYear() === dateB.getFullYear()
	&& dateA.getMonth() === dateB.getMonth()
	&& dateA.getDate() === dateB.getDate()
);

/**
 * @param {Date | string} date
 * @param {import('react-i18next').TFunction} translate
 * @param {boolean} withoutTime
 * @returns {string}
 */
export const formatLiveDate = (date, translate, withoutTime) => {
	const createdDate = new Date(date);
	const today = new Date();

	let mask;

	if (isSameDate(today, createdDate)) {
		mask = `[${translate('Global.DateFormat.at')}] ${translate('Global.DateFormat.time')}`;
	} else if (withoutTime) {
		mask = translate('Global.DateFormat.date');
	} else {
		mask = translate('Global.DateFormat.onDateAtTime');
	}

	return format(createdDate, mask);
};

export const formatChatDate = (createdAt, translate) => {
	const createdDate = new Date(createdAt);

	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	let mask = translate('Global.DateFormat.onDateAtTime');

	if (isSameDate(today, createdDate)) {
		mask = translate('Global.DateFormat.time');
	} else if (isSameDate(yesterday, createdDate)) {
		mask = `[${translate('Global.DateFormat.yesterdayAt')}] ${translate('Global.DateFormat.time')}`;
	}

	return format(createdDate, mask);
};

export function formatDate(value, translate) {
	return value && format(new Date(value), translate('Global.DateFormat.date'));
}
